<template>
  <svg
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.71862 8.90676C8.42949 5.98697 12.9045 4.06207 15.1435 3.13205C21.5365 0.476663 22.8649 0.0153938 23.7307 0.000162401C23.9212 -0.00318758 24.347 0.0439421 24.6228 0.267435C24.8557 0.456147 24.9197 0.711071 24.9504 0.889992C24.9811 1.06891 25.0192 1.4765 24.9889 1.79497C24.6424 5.43 23.1434 14.2512 22.3808 18.3225C22.0581 20.0453 21.4227 20.6229 20.8076 20.6794C19.4707 20.8022 18.4556 19.7972 17.1608 18.9496C15.1348 17.6233 13.9901 16.7977 12.0235 15.5034C9.75066 14.0078 11.224 13.1857 12.5193 11.8422C12.8583 11.4906 18.7484 6.14053 18.8624 5.65519C18.8767 5.59449 18.8899 5.36823 18.7553 5.24875C18.6207 5.12928 18.422 5.17014 18.2787 5.20263C18.0754 5.24869 14.8388 7.38507 8.56858 11.6118C7.64986 12.2418 6.8177 12.5487 6.07212 12.5326C5.25018 12.5149 3.6691 12.0685 2.49372 11.687C1.05207 11.219 -0.0937162 10.9716 0.00605648 10.1768C0.0580243 9.76288 0.628878 9.33952 1.71862 8.90676Z"
    />
  </svg>
</template>
